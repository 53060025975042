import * as vantUi from '@/plugin/vant'
import { $post, $get } from '@/plugin/request'
import { appBaseUrl } from '@/utils/env'
import 'vant/lib/index.css'

// var dayjs = require('dayjs')
// // import dayjs from 'dayjs' // ES 2015
// dayjs().format()
import dayjs from 'dayjs'

import Loading from '@/components/loading'

// 导入vant-ui
const useVantUi = (context, vant) => {
  for (const key in vant) {
    const component = vant[key]
    context.use(component)
  }
}

export default {
  async install (Vue, options) {
    Vue.config.productionTip = false
    Vue.prototype.$post = $post
    Vue.prototype.$get = $get
    Vue.prototype.$baseUrl = appBaseUrl
    Vue.prototype.$dayJs = dayjs

    useVantUi(Vue, vantUi)
    // 为了使用vant-loading,让代码最后执行
    Vue.use(Loading)
  }
}
