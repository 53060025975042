<template>
  <div id="app"
       ref="app"
       class="full-page">
    <router-view />
  </div>
</template>
<script>

export default {
  created () {
  },
  methods: {
    clear () {
      window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybUserInfo')
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/public-class.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.account-btn1 {
  position: fixed !important;
  right: 10px !important;
  bottom: 10px !important;
  border: 20px;
}
</style>
