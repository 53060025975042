<template>
  <div class=" full-page">
    <div v-if="showPage"
         class="login full-page"
         flex="dir:top cross:center">
      <div class="login-bg">
        <van-image width="100%"
                   height="100%"
                   :src="$baseUrl + 'image/login.png'" />
      </div>
      <div class="btn-area">
        <div>
          <phone-login :openid="openid"></phone-login>
        </div>
        <van-button type="primary"
                    block
                    round
                    class="account-btn"
                    @click="handleAccount">账号登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import phoneLogin from './home/phoneLogin.vue'
import { getQueryVariable } from '@/utils/tools.js'
import wx from 'weixin-js-sdk'
export default {
  name: 'login',
  components: {
    phoneLogin
  },
  beforeRouteEnter (to, from, next) {
    const w = location.href.indexOf('?')
    const j = location.href.indexOf('#')
    if (w !== -1 && j > w && getQueryVariable('state')) {
      const state = (getQueryVariable('state')).split('#')[0]
      const redirect_path = JSON.parse(decodeURIComponent(state)).p
      const url =
        location.href.split('/?')[0] +
        '/#' +
        redirect_path +
        `?code=${getQueryVariable('code')}&state=${state}`
      window.localStorage.setItem(window.globalConfig.storePrefix + 'bdybGzhCode', getQueryVariable('code'))
      const u = navigator.userAgent
      // android终端
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        history.go(-1) // 后退
      } else {
        window.location.replace(url)
      }
      return false
    } else {
      next((vm) => { })
    }
  },
  data () {
    return {
      showPage: false,
      openid: '',
      changeAccount: false // 切换账号
    }
  },
  created () {
    console.log("'globalConfig", window.globalConfig)
    this.init()
  },
  mounted () {
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    if (!isAndroid) {
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.backChange, false) // false阻止默认事件
      }
    }
  },
  beforeDestroy () {
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    if (!isAndroid) {
      window.removeEventListener('popstate', this.backChange, false) // false阻止默认事件
    }
  },
  methods: {
    backChange () {
      // alert('关闭微信内置浏览器')
      wx.closeWindow()
    },
    init () {
      // 切换账号
      if (window.sessionStorage.getItem(window.globalConfig.storePrefix + 'bdybChangeUser')) {
        this.initNext()
        return
      }
      // 校验是否登录失效，未生效跳我的账号界面，失效则重新静默授权...
      this.$get('/system/alarmType/list', {
        type: 'customer'
      }).then((res) => {
        const { code } = res
        if (code === 401) {
          this.initNext()
          return
        }
        if (code === 0) {
          this.$router.replace({ path: '/mine' })
        } else {
          this.initNext()
        }
      }).catch((error) => {
        console.log(error)
        this.initNext()
      })
    },
    initNext () {
      const bdybGzhCode = window.localStorage.getItem(window.globalConfig.storePrefix + 'bdybGzhCode') || ''
      if (['undefined', undefined, ''].indexOf(bdybGzhCode) !== -1) {
        this.getAuthCode()
        console.log('获取静默授权code21111111111')
      } else {
        this.getGzhOpenId()
      }
    },
    // 获取静默授权code
    getAuthCode () {
      console.log('获取静默授权code2222222')
      console.log('window.globalConfig', window.globalConfig)
      const getCodeParams = {
        // redirect_uri: window.globalConfig.h5ProjUrl + '/?test=111',
        redirect_uri: window.globalConfig.h5ProjUrl,
        appid: window.globalConfig.storePrefix === 'test' ? 'wx3df460702009419e' : 'wxcac65bcce73f7788',
        scope: 'snsapi_base',
        state: encodeURIComponent(
          JSON.stringify({
            p: '/' // 实际的redirect_uri路由path
          })
        )
      }
      encodeURIComponent(getCodeParams.redirect_uri)
      window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybGzhCode')
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${getCodeParams.appid}&redirect_uri=${getCodeParams.redirect_uri}&response_type=code&scope=${getCodeParams.scope}&state=${getCodeParams.state}#wechat_redirect`
      window.location.replace(url)
      // window.location.href = url
    },
    // 拿静默授权的code取公众号的openId
    async getGzhOpenId () {
      try {
        const bdybGzhCode = window.localStorage.getItem(window.globalConfig.storePrefix + 'bdybGzhCode') || ''
        const params = { code: bdybGzhCode }
        const { data = {}, code } = await this.$get('/officialaccount/accessTokenByCode', params)
        console.log('data', data)
        console.log('window.globalConfig', window.globalConfig)
        if (code === 0 && data.openid) {
          const openid = data.openid
          this.openid = data.openid
          console.log('获取openID成功', this.openid)

          // 存储公众号 openId到本地
          window.localStorage.setItem(window.globalConfig.storePrefix + 'bdybOpenid', openid)
          // 判断openId是否和手机号绑定，绑定直接拿到token，登录成功，否则显示登录入口

          console.log('???? 正确111111')

          if (!window.sessionStorage.getItem(window.globalConfig.storePrefix + 'bdybChangeUser')) {
            this.wxoffLogin(openid)
          } else {
            this.showPage = true
          }

          // alert('getGzhOpenId成功下一步判断OpenId之前有没有成功绑定数据')
        } else {
          // this.showPage = true
          console.log('通过code换取网页授权access_token失败')
          this.$toast('获取openid失败！')
        }
        // 删除静默授权code
        window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybGzhCode')
      } catch (error) {
        // 删除静默授权code
        window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybGzhCode')
        console.log('error')
        // this.showPage = true
        console.log('通过code换取网页授权access_token失败')
        this.$toast('获取openid失败！')
      }
    },
    async wxoffLogin (openId) {
      console.log('wxoffLoginwxoffLoginwxoffLogin')

      try {
        const params = { openId }
        const res = await this.$post('/wxoff/login', params)
        console.log('/wxoff/login', res)
        if (res.code === 0) {
          // 以下逻辑代码登录成功，直接跳登录成功界面
          const userInfo = {
            LeaderView: res.LeaderView,
            customerId: res.customerId,
            customerName: res.customerName,
            imageurl: res.imageurl,
            token: res.token,
            userId: res.userId,
            userName: res.userName
          }
          window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybToken')
          window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybUserInfo')
          window.localStorage.setItem(window.globalConfig.storePrefix + 'bdybToken', res.token)
          window.localStorage.setItem(window.globalConfig.storePrefix + 'bdybUserInfo', JSON.stringify(userInfo))
          const redirecUrl = window.sessionStorage.getItem(window.globalConfig.storePrefix + 'bdybRedirecUrl') || ''
          const query = JSON.parse(window.sessionStorage.getItem(window.globalConfig.storePrefix + 'bdybUrlParameter')) || {}
          if (redirecUrl) {
            window.sessionStorage.removeItem(window.globalConfig.storePrefix + 'bdybRedirecUrl')
            window.sessionStorage.removeItem(window.globalConfig.storePrefix + 'bdybUrlParameter')
            this.$router.replace({ path: redirecUrl, query })
          } else {
            this.$router.replace({ path: '/mine' })
          }
        } else {
          window.sessionStorage.removeItem(window.globalConfig.storePrefix + 'bdybRedirecUrl')
          window.sessionStorage.removeItem(window.globalConfig.storePrefix + 'bdybUrlParameter')
          // alert('登录失效，请重新登录！')
          window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybToken')
          window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybUserInfo')
          this.showPage = true
        }
      } catch (error) {
        console.log('error')
        window.sessionStorage.removeItem(window.globalConfig.storePrefix + 'bdybRedirecUrl')
        window.sessionStorage.removeItem(window.globalConfig.storePrefix + 'bdybUrlParameter')
        window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybToken')
        window.localStorage.removeItem(window.globalConfig.storePrefix + 'bdybUserInfo')
        this.showPage = true
      }
    },

    // 测试
    test () {
      this.$router.replace({ path: '/tests' })
    },
    // 账号登录
    handleAccount () {
      this.$router.replace({ path: '/accountLogin' })
    }

  }
}
</script>
<style lang="scss" scoped>
.login {
  background: linear-gradient(180deg, #415cfe, #6cdafe);
  .login-bg {
    margin-top: 120px;
    width: 306px;
    height: 300px;
  }
  .btn-area {
    position: absolute;
    bottom: 60px;
    width: 340px;
    .account-btn {
      margin-bottom: 20px;
    }
  }
}
</style>
