
import Vue from 'vue'
import axios from 'axios'

import { appBaseApi } from '@/utils/env'
import { Toast } from 'vant'

// const CancelToken = axios.CancelToken

axios.defaults.headers.post['Content-Type'] = 'application/json'

// 创建一个 axios 实例
const service = axios.create({
  // baseURL: appBaseApi,
  // baseURL: 'https://sbyapitest.beidiancloud.cn/smart-space', // 演示环境
  // baseURL: 'http://10.168.1.123:800/smart-space', // 肖璋缘
  baseURL: 'https://zh.beidiancloud.cn/smart-space', // 正式环境
  timeout: 30000
})
// 请求拦截器
service.interceptors.request.use(
  async (config) => {
    const bdybUserInfo = JSON.parse(window.localStorage.getItem(window.globalConfig.storePrefix + 'bdybUserInfo'))
    if (bdybUserInfo && bdybUserInfo.token) {
      config.headers.token = bdybUserInfo.token
    }
    // 除登录接口外，其他接口需要判断是否有token，没有token的请求中断
    // if (url !== 'survey/sc/apploginThird') {
    //   if (!token) {
    //     config.cancelToken = new CancelToken(function executor (cancel) {
    //       cancel('token缺失')
    //     })
    //   }
    // }

    return config
  },
  (error) => {
    // 发送失败
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const data = response.data
    data.code = Number(data.code)
    return data
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.code | 0) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
      const { data = {} } = error.response
      if (data.msg) {
        Toast(data.msg)
      }
      console.error(data)
    }

    return Promise.reject(error)
  }
)

export default service
