<template>
  <div v-show="show"
       class="full-page yk-loading"
       :style="{'zIndex': `${zIndex}`}"
       flex="dir:top main:center cross:center">
    <van-loading class="yk-loading-icon"
                 size="1.2rem"
                 :color="color"
                 vertical />
    <div class="mar-t yk-loading-text">{{text}}</div>
  </div>
</template>

<script>
import { Loading } from 'vant'
export default {
  name: 'yk-loading',
  components: {
    vanLoading: Loading
  },
  props: {
    'show': {
      type: Boolean,
      default: false
    },
    'zIndex': {
      type: Number,
      default: 99
    },
    'text': {
      type: String,
      default: ''
    },
    'color': {
      type: String,
      default: '#1989fa'
    }
  }
}
</script>

<style lang="scss" scoped>
.yk-loading {
  background: rgba(0, 0, 0, 0.6);
  &-icon {
    font-size: 30px;
  }
  &-text {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>
