<template>
  <div class="phoneLogin">
    <div class="open-txt">手机号登录</div>
    <wx-open-launch-weapp id="launch-btn"
                          :appid="env==='test'?'wxe3e220e3a5f58993':'wx09c9602f38c6ebfa'"
                          :username="env==='test'?'gh_efa85717b150':'gh_d651d8fd29df'"
                          :path="path"
                          env-version="release"
                          style="position: absolute;top: 0; left: 0; width: 100%;height: 100% ; opacity: 0;">
      <script type="text/wxtag-template">
        <div style="position: absolute;top: 0; left: 0; width: 100%;height: 100% ; opacity: 0;"></div>
      </script>
    </wx-open-launch-weapp>
    <!-- <wx-open-launch-weapp id="launch-btn"
                          appid="wxe3e220e3a5f58993"
                          username="gh_efa85717b150"
                          :path="path"
                          env-version="release"
                          style="position: absolute;top: 0; left: 0; width: 100%;height: 100% ; opacity: 0;">
      <script type="text/wxtag-template">
        <div style="position: absolute;top: 0; left: 0; width: 100%;height: 100% ; opacity: 0;"></div>
      </script>
    </wx-open-launch-weapp> -->

  </div>
</template>

<script>
const wx = require('weixin-js-sdk')
export default {
  name: 'phone-login',
  components: {
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => { })
  },
  props: {
    // 弹出位置
    openid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      env: window.globalConfig.storePrefix
    }
  },
  computed: {
    path () {
      const url = encodeURIComponent(window.globalConfig.baseUrl + '/phone/wxlogin') // 接口地址
      // alert(url)
      return `subpackage/userlogin/h5login?openid=${this.openid}&url=${url}`
    }
  },
  watch: {
    openid: {
      handler (val, oldVal) {
        console.log('监听openid', val)
        if (val) {
          this.getJsSdk()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    console.log('env', this.env)
  },
  mounted () {
    const _this = this
    const btn = document.getElementById('launch-btn')
    btn.addEventListener('launch', function (e) {
      console.log('success')

      setTimeout(() => {
        document.addEventListener('WeixinJSBridgeReady', function () {
          WeixinJSBridge.call('closeWindow')
        }, false)
        WeixinJSBridge.call('closeWindow')
      }, 500)
    })
    btn.addEventListener('error', function (e) {
      console.log('e', e)
      console.log('fail', e.detail)
    })
  },
  beforeDestory () {
  },
  methods: {
    getJsSdk () {
      this.$get('/officialaccount/getSign', {
        url: window.globalConfig.h5ProjUrl + '/'
      }).then((res) => {
        wx.config({
          debug: false,
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: ['onMenuShareTimeline', 'chooseImage', 'previewImage'], // 必填，需要使用的 JS 接口列表
          openTagList: ['wx-open-launch-weapp']
        })
        wx.ready(() => {
          // 这里是成功后执行
          console.log('wx.config成功了')
        })
        wx.error(() => {
          // 这里是出现异常执行
          console.log('wx.config失敗了')
        })
      }).catch((error) => {
        console.log('error', error)
      })
    },
    // 测试
    test () {
      this.$router.replace('/mine')
    }
  }
}
</script>
<style lang="scss" scoped>
.phoneLogin {
  position: relative;
  width: 100%;
  height: 1.17333rem;
  margin-bottom: 20px;
  line-height: 1.17333rem;
  .open-txt {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    color: #4f89f3;
    background-color: #fff;
    border: 0.02667rem solid #4f89f3;
    border-radius: 26.64rem;
    font-size: 0.37333rem;
    box-sizing: border-box;
  }
}
</style>
