import Loading from './loading.vue'
import Vue from 'vue'

const LoadingConstructor = Vue.extend(Loading)
const instance = new LoadingConstructor({
  el: document.createElement('div')
})
instance.show = false
const loading = {
  show (opts = {}) {
    instance.show = true
    const { text, el, zIndex } = opts
    if (zIndex) {
      instance.zIndex = zIndex
    }
    if (el) {
      instance.$el.style.position = 'absolute'
      el.appendChild(instance.$el)
    } else {
      instance.$el.style.position = 'fixed'
      // 默认挂载到body上
      document.body.appendChild(instance.$el)
    }
    if (text) {
      instance.text = text
    }
  },
  hide (timer = 500) {
    setTimeout(() => {
      instance.show = false
    }, timer)
  }
}

export default {
  install () {
    if (!Vue.$loading) {
      Vue.$loading = loading
    }
    Vue.mixin({
      created () {
        this.$loading = Vue.$loading
      }
      // beforeDestroy () {
      //   this.$loading.hide()
      // }
    })
  }
}
