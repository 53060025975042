import requestAxios from './axios'

/**
 * @description post请求
 * @param {*} url
 * @param {*} data
 * @param {*} headers
 */
export const $post = (url, data, headers) => {
  return requestAxios({
    // headers,
    url,
    data,
    method: 'post'
  })
}

/**
 * @description get请求
 * @param {*} url
 * @param {*} params
 */
export const $get = (url, params) => {
  return requestAxios({
    url,
    params,
    method: 'get'
  })
}
