import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import { loadFile } from '../utils/wxAuth.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  }, {
    path: '/phoneLogin',
    name: '手机号登录',
    component: (resolve) => require(['../views/home/phoneLogin.vue'], resolve)
  },

  {
    path: '/accountLogin',
    name: '账号登录',
    component: (resolve) => require(['../views/home/accountLogin.vue'], resolve)
  },
  {
    path: '/mine',
    name: '我的账号',
    component: (resolve) => require(['../views/home/mine.vue'], resolve)
  },
  {
    path: '/tests',
    name: 'tests',
    component: (resolve) => require(['../views/home/tests.vue'], resolve)
  },
  {
    path: '/subsMsg',
    name: '消息订阅',
    component: (resolve) => require(['../views/subsMsg/index.vue'], resolve)
  },
  {
    path: '/alarmDaily',
    name: '告警日报',
    component: (resolve) => require(['../views/alarm/daily.vue'], resolve)
  },
  {
    path: '/alarmQuery',
    name: '告警查询',
    component: (resolve) => require(['../views/alarm/query.vue'], resolve)
  },
  {
    path: '/details',
    name: '告警查询详情页',
    component: (resolve) => require(['../views/alarm/details.vue'], resolve)
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: (resolve) => require(['../views/home/introduce.vue'], resolve)
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (window.globalConfig.storePrefix === 'pro') {
    // 正式环境
    if (to.path.indexOf('MP_verify_6QfhohcfJEzipyaP') > -1) {
      const url = loadFile('/MP_verify_6QfhohcfJEzipyaP.txt')
      console.log('url', url)
    } else {
      next()
    }
  } else {
    // 演示环境
    if (to.path.indexOf('MP_verify_9cNnoGmfT3I1AZ9F') > -1) {
      const url = loadFile('/MP_verify_9cNnoGmfT3I1AZ9F.txt')
      console.log('url', url)
    } else {
      next()
    }
  }
})

export default router
